<template>
  <div class="main">
    <HeaderView></HeaderView>
    <div class="active-container">
      <div class="brand-introduction">
        <p style="font-size: 48px; line-height: 62px; font-weight: normal; letter-spacing: 4px;">
          职业探索与技能提升
        </p>
        <p style="font-size: 24px; line-height: 36.2px; margin-top: 10px; letter-spacing: 4px;">
          在实践中学习，学习后实践
        </p>
      </div>
    </div>
    <!-- 活动板块 -->
    <div class="active-base">
      <img src="https://kodo.kepinfm.com/website/path-to-active-image-5-2.png" alt="线下活动"
        style="width: 70%; margin: 0 auto;">
      <img src="https://kodo.kepinfm.com/website/path-to-active-image-4.png" alt="线下活动"
        style="width: 70%; margin: 0 auto;">
      <img src="https://kodo.kepinfm.com/website/path-to-active-image-3.png" alt="线下活动"
        style="width: 70%; margin: 0 auto;">
      <img src="https://kodo.kepinfm.com/website/path-to-active-image-2.png" alt="线下活动"
        style="width: 70%; margin: 0 auto;">
      <img src="https://kodo.kepinfm.com/website/path-to-active-image-1.png" alt="线下活动"
        style="width: 70%; margin: 0 auto;">
    </div>
    <!-- 精彩瞬间 -->
    <div class="wonderful_moment">
      <div class="wonderful_moment_content">
        <div class="wonderful_moment_title wow fadeInDown">
          <p class="wonderful_moment_ch">/ 精彩瞬间 /</p>
        </div>
        <div class="wonderful_moment_video wow fadeInUpBig">
          <div class="moment_video" @click="playVideo('https://cdn.kepinfm.com/%20plus/base/kpvideo-1.m4v')">
            <p class="video_title">新媒体运营岗位体验</p>
            <video width="100%" height="100%" poster="https://cdn.kepinfm.com/plus/base/kpvo-1.png">
              <source src="https://cdn.kepinfm.com/%20plus/base/kpvideo-1.m4v" type="video/mp4" />
            </video>
            <p class="home_video_bg"></p>
          </div>
          <div class="moment_video" @click="playVideo('https://cdn.kepinfm.com/plus/base/kpvideo-2.mp4')">
            <p class="video_title">千金研学</p>
            <video width="100%" height="100%" poster="https://cdn.kepinfm.com/plus/base/kpvo-2.png">
              <source src="https://cdn.kepinfm.com/plus/base/kpvideo-2.mp4" type="video/mp4" />
            </video>
            <p class="home_video_bg"></p>
          </div>
          <div class="moment_video" @click="playVideo('https://cdn.kepinfm.com/plus/base/kpvideo-3.mp4')">
            <p class="video_title">校招实习项目</p>
            <video width="100%" height="100%" poster="https://cdn.kepinfm.com/plus/base/kpvo-3.png">
              <source src="https://cdn.kepinfm.com/plus/base/kpvideo-3.mp4" type="video/mp4" />
            </video>
            <p class="home_video_bg"></p>
          </div>
          <div class="moment_video" @click="playVideo('https://cdn.kepinfm.com/%20plus/base/kpvideo-4.m4v')">
            <p class="video_title">天职国际研学</p>
            <video width="100%" height="100%" poster="https://cdn.kepinfm.com/plus/base/kpvo-4.png">
              <source src="https://cdn.kepinfm.com/%20plus/base/kpvideo-4.m4v" type="video/mp4" />
            </video>
            <p class="home_video_bg"></p>
          </div>
        </div>
      </div>
      <div class="play_video" v-if="videoPlayShow">
        <video width="100%" height="100%" controls autoplay disablePictureInPicture="true"
          controlslist="nodownload noplaybackrate" poster="https://cdn.kepinfm.com/plus/base/kpvo-1.png">
          <source :src="videoAddress" type="video/mp4" />
        </video>
        <p class="cancel_video" @click="cancelVideo">X</p>
      </div>
    </div>
    <FooterView></FooterView>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from '@/components/HeaderView.vue'
import FooterView from '@/components/FooterView.vue'

export default {
  name: 'EventView',
  components: {
    HeaderView,
    FooterView
  },
  data() {
    return {
      videoPlayShow: false,
      videoAddress: ''
    }
  },
  methods: {
    // 定义播放视频的方法
    playVideo(address) {
      this.videoPlayShow = true; // 设置视频显示
      this.videoAddress = address; // 设置视频地址
    },
    // 定义取消播放视频的方法
    cancelVideo() {
      this.videoPlayShow = false; // 设置视频不显示
    }
  },
  mounted() {
  },
  computed: {
  }
};
</script>

<style scoped>
.main {
  padding-top: 55px;
  /* 与导航栏高度一致的内边距 */
}

/* 学员好评板块 */
.active-base {
  margin-bottom: 40px;
  /* 添加一些间隔 */
  text-align: center;
  /* 使图片居中显示 */
}

.active-base img {
  max-width: 100%;
  /* 使图片最大宽度为容器宽度 */
  height: auto;
  /* 保持图片的宽高比 */
  border-radius: 5px;
  /* 圆角 */
}

.active-container {
  background-image: url('https://kodo.kepinfm.com/website/jobs/path-to-your-active-image-1.jpg');
  /* 替换为你的背景图片路径 */
  background-size: cover;
  /* 使背景图片覆盖整个容器 */
  background-position: center;
  height: 400px;
  display: flex;
  /* 使用Flexbox */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  position: relative;
  /* 为阴影设置相对定位 */
}

.brand-introduction {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  /* 使用Flexbox */
  flex-direction: column;
  /* 设置为列布局 */
  justify-content: center;
  /* 垂直居中文本 */
  align-items: center;
  /* 水平居中文本 */
}

/* 精彩瞬间 */
.wonderful_moment {
  width: 100%;
  height: 1110px;
  background: url("https://cdn.kepinfm.cn/exp/admin/media/1642410015.png") no-repeat center;
  background-size: cover;
  overflow: hidden;
}

.wonderful_moment_content {
  width: 1000px;
  margin: 0 auto;
}

.wonderful_moment_title {
  box-sizing: border-box;
  padding-bottom: 30px;
  border-bottom: 2px solid #A7953D;
}

.wonderful_moment_en {
  width: 360px;
  margin: 160px auto 0;
  text-align: center;
  font-size: 32px;
  font-family: '思源';
  color: #FDD529;
  height: 20px;
  border-radius: 6px;
}

.wonderful_moment_ch {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  font-size: 30px;
  font-family: '思源';
  color: #FDD529;
  margin-top: 40px;
}

.wonderful_moment_video {
  width: 990px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.moment_video {
  width: 450px;
  height: 240px;
  margin-top: 80px;
  cursor: pointer;
  position: relative;
}

.moment_video video {
  width: 100%;
  height: 100%;
}


.moment_video:hover {
  background-color: #3D4043;
}

.video_title {
  color: #BCBDBE;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  position: relative;
  box-sizing: border-box;
  padding-left: 16px;
  margin-left: 10px;
  margin-bottom: 10px;
  font-family: '思源';
}

.video_title::after {
  content: '';
  position: absolute;
  left: 0;
  width: 6px;
  height: 30px;
  border-radius: 4px;
  background-color: white;
}

.moment_video:hover .video_title {
  color: #ffffff;
}

.moment_video:hover .video_title::after {
  content: '';
  position: absolute;
  left: 0;
  width: 6px;
  height: 30px;
  border-radius: 4px;
  background-color: #FDD529;
}

.home_video_bg {
  width: 60px;
  height: 60px;
  background: url('https://kodo.kepinfm.com/website/home_play.png') no-repeat center;
  background-size: cover;
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

/* 播放视频 */
.play_video {
  width: 60%;
  height: 70%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.play_video video {
  width: 100%;
  height: 100%;
}

.cancel_video {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  color: #FDD529;
  position: absolute;
  top: -10px;
  right: 0;
  font-size: 20px;
  cursor: pointer;
}

.cancel_video:hover {
  background-color: red;
}
</style>