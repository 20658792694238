<template>
  <div class="navbar-container">
    <div class="navbar" :class="{ 'bg-white': isSticky, 'text-black': isSticky }" ref="nav">
      <div class="navbar-content">
        <img :src="logoPath" alt="课聘" class="logo" @click="toHomePage()">
        <div class="navbar-right">
          <a v-for="(item, index) in navItems" :key="index" :href="item.href" :class="{ active: item.href === currentPath, name: true }">
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderView',
  data() {
    return {
      isSticky: false,
      logoPath: 'https://kodo.kepinfm.com/website/logo.png', // 替换为您的logo图片路径 job、student、faculty、event
      navItems: [{href: '/about', name: '关于我们'}, {href: '/job', name: '求职服务'}, {href: '/student', name: '学员反馈'}, {href: '/faculty', name: '师资力量'}, {href: '/event', name: '活动掠影'}]
    };
  },
  computed: {
    currentPath() {
      return window.location.pathname;  // 获取当前页面的路径
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toHomePage(){
      this.$router.replace("/").catch(err => { console.log(err) })
    },
    handleFocus(event) {
      event.target.style.fontWeight = 'bold';
    },
    handleScroll() {
      this.isSticky = window.scrollY > 0;
    },
    handleMouseOver() {
      this.$el.querySelectorAll('a').forEach(a => {
        a.style.fontWeight = a === event.target ? 'bold' : 'normal';
      });
    },
    handleMouseLeave() {
      this.$el.querySelectorAll('a').forEach(a => {
        a.style.fontWeight = this.currentPath.includes(a.getAttribute('href')) ? 'bold' : 'normal';
      });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar-container {
  /* 水平居中 */
  display: flex;
  align-items: center;
  /* 垂直居中 */
  justify-content: space-between;
  background-color: #fff;
  z-index: 99;
  position: relative;
  width: 100%;
}

.navbar-content {
  display: flex;
  align-items: center;
  /* 垂直居中 */
}

.navbar {
  overflow: hidden;
  // background-color: transparent;
  position: fixed;
  top: 0;
  // transition: background-color 0.3s ease;
  height: 55px;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10%;
}

.navbar .name {
  float: left;
  display: block;
  color: #fdcd00;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  transition: font-weight 0.3s ease;
}

.navbar a:hover,
.navbar a:focus {
  font-weight: bold;
}

.navbar img {
  float: left;
  width: 128px;
  height: 30px;
  margin: 5px 10px;
  cursor: pointer;
}

.navbar-right {
  float: right;
  margin-left: 10px;
}

@media screen and (max-width: 600px) {
  .navbar a {
    float: none;
    display: block;
    text-align: left;
  }
}
/* 添加 .active 类的样式 */
.active {
  font-weight: bold;
  color: #fdcd00 !important; /* 或者您选择的高亮颜色 */
}
</style>
