import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import JobView from '../views/JobView.vue'
import StudentView from '../views/StudentView.vue'
import FacultyView from '../views/FacultyView.vue'
import EventView from '../views/EventView.vue'
import ProductIntroductionView from '../views/ProductIntroductionView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/job',
    name: 'job',
    component: JobView
  },
  {
    path: '/student',
    name: 'student',
    component: StudentView
  },
  {
    path: '/faculty',
    name: 'faculty',
    component: FacultyView
  },
  {
    path: '/event',
    name: 'event',
    component: EventView
  },
  {
    path: '/product-introduction/:query',
    name: 'ProductIntroduction',
    component: ProductIntroductionView
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
