var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('HeaderView'),_vm._m(0),_vm._m(1),_c('div',{staticClass:"wonderful_moment"},[_c('div',{staticClass:"wonderful_moment_content"},[_vm._m(2),_c('div',{staticClass:"wonderful_moment_video wow fadeInUpBig"},[_c('div',{staticClass:"moment_video",on:{"click":function($event){return _vm.playVideo('https://cdn.kepinfm.com/%20plus/base/kpvideo-1.m4v')}}},[_c('p',{staticClass:"video_title"},[_vm._v("新媒体运营岗位体验")]),_vm._m(3),_c('p',{staticClass:"home_video_bg"})]),_c('div',{staticClass:"moment_video",on:{"click":function($event){return _vm.playVideo('https://cdn.kepinfm.com/plus/base/kpvideo-2.mp4')}}},[_c('p',{staticClass:"video_title"},[_vm._v("千金研学")]),_vm._m(4),_c('p',{staticClass:"home_video_bg"})]),_c('div',{staticClass:"moment_video",on:{"click":function($event){return _vm.playVideo('https://cdn.kepinfm.com/plus/base/kpvideo-3.mp4')}}},[_c('p',{staticClass:"video_title"},[_vm._v("校招实习项目")]),_vm._m(5),_c('p',{staticClass:"home_video_bg"})]),_c('div',{staticClass:"moment_video",on:{"click":function($event){return _vm.playVideo('https://cdn.kepinfm.com/%20plus/base/kpvideo-4.m4v')}}},[_c('p',{staticClass:"video_title"},[_vm._v("天职国际研学")]),_vm._m(6),_c('p',{staticClass:"home_video_bg"})])])]),(_vm.videoPlayShow)?_c('div',{staticClass:"play_video"},[_c('video',{attrs:{"width":"100%","height":"100%","controls":"","autoplay":"","disablePictureInPicture":"true","controlslist":"nodownload noplaybackrate","poster":"https://cdn.kepinfm.com/plus/base/kpvo-1.png"}},[_c('source',{attrs:{"src":_vm.videoAddress,"type":"video/mp4"}})]),_c('p',{staticClass:"cancel_video",on:{"click":_vm.cancelVideo}},[_vm._v("X")])]):_vm._e()]),_c('FooterView')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"active-container"},[_c('div',{staticClass:"brand-introduction"},[_c('p',{staticStyle:{"font-size":"48px","line-height":"62px","font-weight":"normal","letter-spacing":"4px"}},[_vm._v(" 职业探索与技能提升 ")]),_c('p',{staticStyle:{"font-size":"24px","line-height":"36.2px","margin-top":"10px","letter-spacing":"4px"}},[_vm._v(" 在实践中学习，学习后实践 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"active-base"},[_c('img',{staticStyle:{"width":"70%","margin":"0 auto"},attrs:{"src":"https://kodo.kepinfm.com/website/path-to-active-image-5-2.png","alt":"线下活动"}}),_c('img',{staticStyle:{"width":"70%","margin":"0 auto"},attrs:{"src":"https://kodo.kepinfm.com/website/path-to-active-image-4.png","alt":"线下活动"}}),_c('img',{staticStyle:{"width":"70%","margin":"0 auto"},attrs:{"src":"https://kodo.kepinfm.com/website/path-to-active-image-3.png","alt":"线下活动"}}),_c('img',{staticStyle:{"width":"70%","margin":"0 auto"},attrs:{"src":"https://kodo.kepinfm.com/website/path-to-active-image-2.png","alt":"线下活动"}}),_c('img',{staticStyle:{"width":"70%","margin":"0 auto"},attrs:{"src":"https://kodo.kepinfm.com/website/path-to-active-image-1.png","alt":"线下活动"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wonderful_moment_title wow fadeInDown"},[_c('p',{staticClass:"wonderful_moment_ch"},[_vm._v("/ 精彩瞬间 /")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('video',{attrs:{"width":"100%","height":"100%","poster":"https://cdn.kepinfm.com/plus/base/kpvo-1.png"}},[_c('source',{attrs:{"src":"https://cdn.kepinfm.com/%20plus/base/kpvideo-1.m4v","type":"video/mp4"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('video',{attrs:{"width":"100%","height":"100%","poster":"https://cdn.kepinfm.com/plus/base/kpvo-2.png"}},[_c('source',{attrs:{"src":"https://cdn.kepinfm.com/plus/base/kpvideo-2.mp4","type":"video/mp4"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('video',{attrs:{"width":"100%","height":"100%","poster":"https://cdn.kepinfm.com/plus/base/kpvo-3.png"}},[_c('source',{attrs:{"src":"https://cdn.kepinfm.com/plus/base/kpvideo-3.mp4","type":"video/mp4"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('video',{attrs:{"width":"100%","height":"100%","poster":"https://cdn.kepinfm.com/plus/base/kpvo-4.png"}},[_c('source',{attrs:{"src":"https://cdn.kepinfm.com/%20plus/base/kpvideo-4.m4v","type":"video/mp4"}})])
}]

export { render, staticRenderFns }