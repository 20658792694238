<template>
  <div class="main">
    <HeaderView></HeaderView>
    <div class="job-container">
      <div class="brand-introduction">
        <p style="font-size: 48px; line-height: 62px; font-weight: normal; letter-spacing: 4px;">
          定制您的专属求职方案
        </p>
        <p style="font-size: 24px; line-height: 36.2px; margin-top: 10px; letter-spacing: 4px;">
          多年实战考验，满足全阶段求职需求
        </p>
      </div>
    </div>
    <div class="gallery-container">
      <h2 class="section-title">求职流程全覆盖</h2>
      <div class="image-row">
        <div class="image-description" @click="toProductPage('peipao')">
            <img src="https://kodo.kepinfm.com/website/jobs/path-to-qz-image-2-1.png" alt="陪跑营" class="gallery-image">
            <div class="text">
              <p class="text1">校招求职陪跑营</p>
              <p class="text2">提前进行求职准备和能力提升，以拿到心仪offer为最终目标</p>
            </div>
        </div>
        <div class="image-description" @click="toProductPage('chongci')">
            <img src="https://kodo.kepinfm.com/website/jobs/path-to-qz-image-1-1.png" alt="冲刺营" class="gallery-image">
            <div class="text">
              <p class="text1">校招求职冲刺营</p>
              <p class="text2">用最短的时间、最高的效率，快速提高求职能力</p>
            </div>
        </div>
      </div>
    </div>
    <div class="gallery-container">
      <h2 class="section-title">1对1个性化服务</h2>
      <div class="image-row">
        <div class="image-description" @click="openModal">
          <img src="https://kodo.kepinfm.com/website/jobs/path-to-v-image-1-1.png" alt="职业规划" class="gallery-image">
          <div class="text">
            <p class="text1">1对1面试指导</p>
            <p class="text2">讲解面试技巧，进行实战模拟，发掘自身面试表现不足，并针对性复盘、讲解破题思路，提升面试表现</p>
          </div>
        </div>
        <div class="image-description" @click="openModal">
          <img src="https://kodo.kepinfm.com/website/jobs/path-to-v-image-2-1.png" alt="简历制作" class="gallery-image">
          <div class="text">
            <p class="text1">1对1简历制作</p>
            <p class="text2">结合求职目标，梳理过往经历，挖掘并强化自身优势，全方位提升简历的综合竞争力</p>
          </div>
        </div>
        <div class="image-description" @click="openModal">
          <img src="https://kodo.kepinfm.com/website/jobs/path-to-v-image-3-1.png" alt="面试指导" class="gallery-image">
          <div class="text">
            <p class="text1">1对1职业规划</p>
            <p class="text2">从个人兴趣、能力模型、经历背景等多个维度评估学员的求职竞争力，确定求职目标</p>
          </div>
        </div>
      </div>
    </div>

    <div class="gallery-container">
      <h2 class="section-title">求职技能快速提升</h2>
      <div class="image-row" style="margin-bottom: 40px;">
        <div class="image-description" @click="openModal">
          <img src="https://kodo.kepinfm.com/website/jobs/qz-1.png" alt="职业规划" class="gallery-image">
          <div class="text">
            <p class="text1">职业生涯规划课</p>
            <p class="text2">找到自己喜欢并与个人背景匹配的求职目标，快速了解不同行业及岗位，明确未来职业规划</p>
          </div>
        </div>
        <div class="image-description" @click="openModal">
          <img src="https://kodo.kepinfm.com/website/jobs/qz-2.png" alt="简历制作" class="gallery-image">
          <div class="text">
            <p class="text1">笔试系统精讲课</p>
            <p class="text2">笔试题型全覆盖，常考问题及破题思路分类讲解，建立快速解题思维，助你轻松拿高分</p>
          </div>
        </div>
        <div class="image-description" @click="openModal">
          <img src="https://kodo.kepinfm.com/website/jobs/qz-3.png" alt="面试指导" class="gallery-image">
          <div class="text">
            <p class="text1">面试考前冲刺课</p>
            <p class="text2">单面、群面、结构化、半结构化面试，高频考点讲解，面试常陷误区分析，掌握面试“说话之道”</p>
          </div>
        </div>
      </div>
      <div class="image-row" style="margin-bottom: 60px;">
        <div class="image-description" @click="openModal">
          <img src="https://kodo.kepinfm.com/website/jobs/qz-4.png" alt="职业规划" class="gallery-image">
          <div class="text">
            <p class="text1">校招就业指南</p>
            <p class="text2">毕业后有哪些选择？体制内体制外就业有何不同？各行业求职偏好解读，洞悉求职全流程</p>
          </div>
        </div>
        <div class="image-description" @click="openModal">
          <img src="https://kodo.kepinfm.com/website/jobs/qz-5.png" alt="简历制作" class="gallery-image">
          <div class="text">
            <p class="text1">点击获取更多求职课程</p>
            <p class="text2">全面提升求职硬实力</p>
          </div>
        </div>
      </div>
    </div>
        <!-- 咨询窗口/客服联系方式板块 -->
    <div class="contact-container">
      <h2 class="section-title">咨询窗口</h2>
      <div class="external-link" style="cursor: pointer;" @click="openExternalLink">
        <form @submit.prevent="handleSubmit" class="contact-form">
          <!-- 左侧列 -->
          <div class="form-column">
            <!-- 姓名 -->
            <div class="form-group">
              <label for="name">姓名:</label>
              <input type="text" id="name" v-model="form.name" placeholder="点击框外输入相关信息" required disabled data-skip-submit>
            </div>

            <!-- 手机号码 -->
            <div class="form-group">
              <label for="mobile">手机号码 (中国大陆 +86):</label>
              <input type="text" id="mobile" v-model="form.mobile" required disabled>
            </div>

            <!-- 微信号 -->
            <div class="form-group">
              <label for="wechat">微信号:</label>
              <input type="text" id="wechat" v-model="form.wechat" disabled>
            </div>
          </div>

          <!-- 右侧列 -->
          <div class="form-column right-column">
            <!-- 学历和学校名称在同一行 -->
            <div class="form-group education-and-university">
              <div class="education">
                <label for="education">学历:</label>
                <select id="education" v-model="form.education" required disabled>
                  <option value="undergraduate">本科</option>
                  <option value="master">硕士</option>
                  <option value="doctor">博士</option>
                  <!-- 其他学历选项 -->
                </select>
              </div>
              <div class="university">
                <label for="university">学校名称:</label>
                <input type="text" id="university" v-model="form.university" required disabled>
              </div>
            </div>

            <!-- 毕业时间 -->
            <div class="form-group">
              <label for="graduation">毕业时间:</label>
              <input type="date" id="graduation" v-model="form.graduation" required disabled>
            </div>

            <!-- 意向行业 -->
            <div class="form-group">
              <label for="industry">意向行业:</label>
              <input type="text" id="industry" v-model="form.industry" required disabled>
            </div>
          </div>
        </form>
      </div>
      <!-- 提交按钮和提示信息 -->
      <div class="form-actions">
        <button type="submit" :disabled="!isFormValid" class="actions">预约测评</button>
        <p>您的信息已加密，请放心填写。</p>
      </div>
    </div>
    <DialogBox :imageUrl="imagePath" :isVisible="isModalVisible" @close="closeModal"></DialogBox>
    <FooterView></FooterView>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from '@/components/HeaderView.vue'
import FooterView from '@/components/FooterView.vue'
import DialogBox from '@/components/DialogBox.vue';

export default {
  name: 'JobView',
  components: {
    HeaderView,
    FooterView,
    DialogBox
  },
  data() {
    return {
      imagePath: 'https://kodo.kepinfm.com/website/path-to-kefu-img.jpg', // 图片路径
      isModalVisible: false,
      form: {
        name: '',
        mobile: '',
        wechat: '',
        education: 'undergraduate', // 默认学历为本科
        university: '',
        graduation: '',
        industry: ''
      }
    }
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    handleSubmit() {
      if (this.isFormValid) {
        // 表单提交逻辑
        console.log('Form submitted:', this.form);
        alert('表单提交成功！');
      } else {
        // 表单校验失败逻辑
        alert('请填写所有必填项！');
      }
    },
    openExternalLink() {
      window.open('http://kepin001.mikecrm.com/C7w4ifs',  '_blank');
    },
    toProductPage(query){
      this.$router.push({ 
        name: 'ProductIntroduction', 
        query: { 
          keyword: query 
        } 
});
      
    }
  },
  mounted() {
  },
  computed: {
    isFormValid() {
      // 简单的字段校验逻辑
      return (
        this.form.name.trim() !== '' &&
        this.form.mobile.trim() !== '' &&
        this.form.university.trim() !== '' &&
        this.form.graduation !== '' &&
        this.form.industry.trim() !== ''
      );
    }
  }
};
</script>

<style scoped>
.main {
  padding-top: 55px;
  /* 与导航栏高度一致的内边距 */
}

.index-carousel {
  position: relative;
  width: 1160px;
  margin: 0 auto;
}

.wrap {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.index-carousel .carousel {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.section-title {
  font-size: 1.8em;
  /* 字体大2号 */
  font-weight: bold;
  /* 加粗 */
  text-align: center;
  /* 文本居中 */
  margin-bottom: 40px;
  /* 与内容保持一定距离 */
  letter-spacing: 2px;
  margin-top: 60px;
}

/* 产品 */
.gallery-container {
  margin: 0 auto;
  text-align: center;
  /* 图片水平居中 */
}

.image-row {
  display: flex;
  justify-content: center;
  /* 水平居中排列图片 */
  margin-bottom: 60px;
  /* 行与标题之间的间距 */
}


/* 如果需要适配移动设备 */
@media (max-width: 768px) {
  .gallery-image {
    width: 100%;
    /* 在小屏幕上图片宽度设为100% */
    height: auto;
    /* 高度自适应 */
    margin: 5px 0;
    /* 调整间距 */
  }
}

.job-container {
  background-image: url('https://kodo.kepinfm.com/website/path-to-job-image-1.jpg');
  /* 替换为你的背景图片路径 */
  background-size: cover;
  /* 使背景图片覆盖整个容器 */
  background-position: center;
  height: 400px;
  display: flex;
  /* 使用Flexbox */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  position: relative;
  /* 为阴影设置相对定位 */
}

.brand-introduction {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  /* 使用Flexbox */
  flex-direction: column;
  /* 设置为列布局 */
  justify-content: center;
  /* 垂直居中文本 */
  align-items: center;
  /* 水平居中文本 */
}

.image-description p {
  text-align: left;
}

.image-description .text1 {
  font-weight: bold;
  margin-bottom: 4px;
}

.image-description .text2 {
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 1.8;
  color: #666;
}

.gallery-image {
  width: 100%;
  /* 图片宽度设为100%，撑满容器 */
  height: auto;
  /* 高度自适应，保持宽高比 */
  margin: 0;
  /* 根据需要调整图片的外边距 */
  border-radius: 4px 4px 0 0;
  /* 图片圆角 */
  cursor: pointer;
}

.image-description {
  position: relative;
  /* 为文本容器定位 */
  display: inline-block;
  /* 使图像描述为行内块元素 */
  width: 307px;
  /* 确保图像描述容器宽度与图片相同 */
  margin: 0 10px;
  background-color: #f6f9fb;
}

.text {
  background-color: #f6f9fb;
  padding: 12px;
  /* 添加内边距 */
  word-wrap: break-word;
  /* 允许长单词或链接换行 */
}

.image-description a {
  text-decoration: none;
  /* 去除下划线 */
  color: inherit;
  /* 保持与周围文本相同的颜色 */
}

.image-description a:hover,
.image-description a:focus {
  text-decoration: none;
  /* 鼠标悬停或聚焦时不显示下划线 */
  color: inherit;
  /* 保持颜色不变 */
}

/* 如果您还想去除点击时的边框，可以添加以下样式 */
.image-description a:active {
  outline: none;
  /* 去除边框 */
}

/* 咨询窗口/客服联系方式 */
.contact-container {
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 64px;
  padding-top: 60px;
  background-color: #f6f9fb;
}

.form-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

/* 表单样式 */
.contact-form {
  display: center;
  justify-content: space-between;
  max-width: 800px;
  /* 表单的最大宽度 */
  margin: 0 auto;
  /* 水平居中 */
  padding: 20px;
  /* 内边距 */
  border: 1px solid #ccc;
  /* 边框 */
  border-radius: 5px;
  /* 边框圆角 */
}

.form-column {
  flex-basis: calc(50% - 20px);
  /* 两列的宽度，减去间隔 */
  padding: 0 10px;
  /* 列之间的间距 */
  box-sizing: border-box;
  /* 包含边框和内边距在宽度内 */
}

.form-group {
  margin-bottom: 20px;
  /* 表单项之间的间距 */
}

label {
  display: block;
  /* 使标签独占一行 */
  margin-bottom: 5px;
  /* 标签与输入框之间的间距 */
}

input[type="text"],
input[type="date"],
select {
  width: 100%;
  padding: 10px;
  margin-top: 0;
  /* 由于标签独占一行，这里不需要额外的间距 */
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
}

/* 响应式设计调整 */
@media (max-width: 768px) {
  .form-column {
    flex-basis: 100%;
    margin-bottom: 20px;
  }

  .contact-form {
    flex-direction: column;
    align-items: center;
  }

  .education-and-university {
    flex-direction: column;
  }

  .education-and-university .education,
  .education-and-university .university {
    margin-right: 0;
    padding-right: 0;
  }

  .education-and-university .education {
    margin-bottom: 10px;
    /* 在小屏幕上学历和学校名称的间距 */
  }

  .form-actions {
    flex-direction: column;
    align-items: stretch;
  }

  button[type="submit"] {
    margin-bottom: 15px;
    /* 在小屏幕上为按钮添加间隔 */
  }

  .form-info {
    text-align: center;
    /* 在小屏幕上将提示信息居中对齐 */
  }
}

.education-and-university {
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  margin-bottom: 20px;
  /* 与其它表单项的间距 */
}

.education-and-university .education,
.education-and-university .university {
  flex: 1;
  /* 让学历和学校名称各占一半空间 */
  margin-right: 10px;
  /* 右侧间距 */
  padding-right: 10px;
  /* 右侧内边距，避免边框重叠 */
  box-sizing: border-box;
  /* 包含边框和内边距在宽度内 */
}

.education-and-university .education {
  margin-right: 20px;
  /* 学历和学校名称之间的间距 */
}

/* 确保学历和学校名称的标签和输入框/选择框垂直居中对齐 */
.education-and-university label,
.education-and-university select,
.education-and-university input {
  display: block;
}

button[type="submit"] {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  /* 文字颜色，浅色背景时使用深色文字 */
  background-color: #f6f9fb;
  /* 静止状态的浅色背景 */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
  /* 按钮与提示信息之间的间距 */
}

button[type="submit"]:hover {
  background-color: #fdcd00;
}

button[type="submit"]:disabled {
  background-color: #fdcf0067;
  cursor: not-allowed;
}

.form-info {
  font-size: 0.9em;
  /* 提示信息的字体大小为正常字体大小的90% */
  color: #6c757d;
  /* 提示信息的字体颜色为灰色 */
  margin: 0;
  /* 移除默认的外边距 */
}
/* 禁止输入项的样式 */
input[disabled],
select[disabled] {
  /* 禁用时的背景色 */
  cursor: pointer;
  /* 鼠标悬停时显示不允许的手势 */
}

.external-link {
  text-decoration: none;
  /* 去除下划线 */
  color: inherit;
  /* 链接颜色继承自父元素 */
  display: block;
  /* 使链接作为一个块级元素 */
}

</style>