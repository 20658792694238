<template>
  <footer class="footer-container">
    <div class="footer-content">
      <div class="footer-logo">
        <img :src="logoPath" alt="课聘" class="logo">
        <p class="mission-statement">为普通年轻人创造机会</p>
      </div>
      <div class="footer-details">
        <p>公司：湖南绊界网络科技有限公司</p>
        <p>地址：湖南省长沙市岳麓区蓝杉大厦(总部)</p>
        <p>Email: kepin001@163.com</p>
        <p>网址：www.kepinfm.com</p>
      </div>
      <div class="footer-qrcode">
        <div class="qrcode-item">
          <img src="https://kodo.kepinfm.com/website/path-to-qr-img.jpg" alt="课聘官方公众号">
          <p class="qrcode-description">课聘官方公众号</p>
        </div>
        <div class="qrcode-item">
          <img src="https://kodo.kepinfm.com/website/path-to-kefu-img.jpg" alt="课聘 果果学姐">
          <p class="qrcode-description">果果学姐</p>
        </div>
      </div>
    </div>
    <div class="copyright">
          <p>
            <span>Copyright © 2020 课聘 湖南绊界网络科技有限公司</span>
            <span><a href="https://beian.miit.gov.cn/" rel="nofollow" ka="link-beian" target="_blank"><img
                  src="https://cdn.kepinfm.com/plus/base/icon-beian.png" alt=""> 湘ICP备18017667号</a></span>
          </p>
        </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterView',
  data() {
    return {
      logoPath: 'https://kodo.kepinfm.com/website/logo.png', // 替换为您的公司logo图片路径
    };
  },
  // 其他选项...
};
</script>

<style scoped>
.footer-container {
  background-color: #F5F5F5;
  /* 背景颜色 */
  padding: 20px 0;
  /* 内边距 */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column; /* 垂直排列 */
  justify-content: center; /* 垂直居中 */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  /* 水平居中 */
}

.footer-logo {
  /* logo区域样式 */
  text-align: left;
}

.logo {
  width: 128px;
  /* Logo宽度，根据需要调整 */
  height: auto;
  /* 高度自适应 */
}

.mission-statement {
  color: #000;
  /* 字体颜色为黑色 */
  font-size: 0.9em;
  /* 根据需要调整字体大小 */
  margin: 5px 0;
  /* 根据需要调整上下间距 */
}

.footer-details p {
  margin: 5px 0;
  /* 段落间距 */
}

.footer-qrcode {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.qrcode-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 10px;
}

.qrcode-item img {
  width: 100px;
  /* 二维码宽度，根据需要调整 */
  height: 100px;
  /* 二维码高度，保持宽高比 */
  margin-bottom: 5px;
  /* 二维码与说明文字的间距 */
}

.qrcode-description {
  color: #000;
  /* 字体颜色为黑色 */
  font-size: 0.9em;
  /* 根据需要调整字体大小 */
}

.copyright {
  color: #eeeff3;
  font-size: 12px;
  max-width: 900px;
  border-top: 1px solid rgb(99, 99, 99);
  padding-top: 16px;
  text-align: center;
}

.copyright span {
  color: #8d92a1;
  display: inline-block;
  margin-right: 15px;
}

.copyright img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin: -3px 2px 0 0;
}

.copyright a {
  text-decoration: none;
  color: #8d92a1;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-qrcode {
    width: 100%;
    /* 在小屏幕上宽度为100% */
    flex-direction: column;
    align-items: center;
  }

  .qrcode-item {
    margin-bottom: 15px;
    /* 在小屏幕上添加间距 */
  }
}
</style>