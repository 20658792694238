<template>
  <div class="container-box">
    <!-- 页面其他内容 -->
    <div class="floating-component">
      <img src="https://kodo.kepinfm.com/website/path-to-kefu-img.jpg" alt="扫码咨询" class="qr-code">
      <p style="background-color: #fff; padding-bottom: 16px;">扫码咨询</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightBox'
  // 组件逻辑
}
</script>

<style scoped>
.container-box {
  position: relative; /* 确保父容器是相对定位 */
  /* 其他样式 */
}

.floating-component {
  width: 200px;
  height: 200px;
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: #fff;
  text-align: center;
}

.qr-code {
  width: 100%; /* 图片宽度100% */
  height: auto; /* 高度自动，保持图片比例 */
  /* 其他样式 */
}
</style>