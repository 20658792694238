<template>
  <div class="main">
    <HeaderView></HeaderView>

    <div class="container">
      <h1 style="margin-top: 50px; margin-bottom: 10px;">{{ product_o?.productName }}</h1>
      <div class="product-line"></div>
      <div style="box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">
        <template v-for="(img, index) in product_o?.images">
          <img :src="img" :key="index" alt="商品图片" class="product-image">
        </template>
      </div>
    </div>
    <RightBoxView></RightBoxView>
  </div>
</template>

<script>
import HeaderView from '@/components/HeaderView.vue'
import RightBoxView from '@/components/RightBox.vue'

export default {
  name: 'ProductIntroduction',
  components: {
    HeaderView,
    RightBoxView
  },
  data() {
    return {
      product_n: {
        peipao: {
          productName: '校招求职陪跑营',
          images: [
            'https://kodo.kepinfm.com/website/jobs/card/peipao/p-1-1.png'
          ]
        },
        chongci: {
          productName: '校招求职冲刺营',
          images: [
            'https://kodo.kepinfm.com/website/jobs/card/chongci/c-1-1.png'
          ]
        }
      },
      product_o: {}
    }
  },
  created() {
    this.setProductDetails();
  },
  methods: {
    setProductDetails() {
      const query = this.$route.query;
      if (query.keyword === 'peipao') {
        this.product_o = this.product_n.peipao
      } else {
        this.product_o = this.product_n.chongci
      }
    }
  }
}
</script>

<style scoped>
.main {
  padding-top: 55px;
  background-color: #f6f9fb;
  padding-bottom: 30px;
}

.container {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.product-image {
  max-width: 100%;
  height: auto;
}
.product-line {
  height: 4px; /* 线条高度 */
  background-color: #fdcd00; /* 线条颜色 */
  border: none; /* 移除默认边框 */
  margin: 0 auto; /* 上下外边距为0，左右自动，使线条水平居中 */
  width: 100px;
  margin-bottom: 50px;
}
</style>