<template>
  <div class="modal" v-if="isVisible">
    <div class="modal-content" @click.stop>
      <img :src="imageUrl" class="modal-image" alt="图片">
      <div class="scan-code-text">详情咨询请扫码</div>
      <button class="close-button" @click="closeModal">X</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogBoxComponent',
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
    };
  },
  methods: {
    closeModal() {
      this.$emit('close') // 触发一个事件，通知父组件关闭弹窗
    }
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 蒙层 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 90%;
}

.modal-image {
  width: 300px;
  height: auto;
  display: block; /* 使图片宽度自适应 */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
}
.scan-code-text {
  font-size: 1.2em; /* 根据需要调整字体大小 */
  color: #666; /* 文本颜色 */
  text-align: center; /* 文本居中 */
  margin-top: 10px; /* 与图片的间距 */
}
</style>