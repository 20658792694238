<template>
  <div class="main">
    <HeaderView></HeaderView>
    <div class="student-container">
      <div class="brand-introduction">
        <p style="font-size: 48px; line-height: 62px; font-weight: normal; letter-spacing: 4px;">
          可信赖的职前辅导
        </p>
        <p style="font-size: 24px; line-height: 36.2px; margin-top: 10px; letter-spacing: 4px;">
          学员好评率98%，以专业化服务赢得口碑保障
        </p>
      </div>
    </div>
    <!-- 学员好评板块 -->
    <div class="student-testimonials">
      <h2 class="section-title">学员好评</h2>
      <div class="testimonials-row">
        <img src="https://kodo.kepinfm.com/website/card/to-path-card-img-1.png" alt="学员好评"
          style="width: 30%; margin-right: 20px;">
        <img src="https://kodo.kepinfm.com/website/card/to-path-card-img-2.png" alt="学员好评" style="width: 30%;">
      </div>
      <div class="testimonials-row">
        <img src="https://kodo.kepinfm.com/website/card/to-path-card-img-3.png" alt="学员好评"
          style="width: 30%; margin-right: 20px;">
        <img src="https://kodo.kepinfm.com/website/card/to-path-card-img-4.png" alt="学员好评" style="width: 30%;">
      </div>
      <div class="testimonials-row">
        <img src="https://kodo.kepinfm.com/website/card/to-path-card-img-5.png" alt="学员好评"
          style="width: 30%; margin-right: 20px;">
        <img src="https://kodo.kepinfm.com/website/card/to-path-card-img-6.png" alt="学员好评" style="width: 30%;">
      </div>
    </div>
    <!-- 学员就职企业 -->
    <div class="cooperative-enterprises">
      <h2 class="section-title">学员就职企业</h2>
      <img src="https://kodo.kepinfm.com/website/path-to-company-image-1.png" alt="合作企业"
        style="width: 60%; margin: 0 auto;">
    </div>
    <!-- 学员分布 -->
    <div class="student-distribution">
      <h2 class="section-title">学员分布</h2>
      <img src="https://kodo.kepinfm.com/website/path-to-map-image.png" alt="学员分布"
        style="width: 50%; margin: 0 auto;">
      <img src="https://kodo.kepinfm.com/website/path-to-school-image-1.png" alt="学员分布"
        style="width: 60%; margin: 0 auto;">
    </div>
    <!-- 学员去向板块 -->
    <div class="student-destination">
      <h2 class="section-title">学员 OFFER 榜</h2>
      <img src="https://kodo.kepinfm.com/website/path-to-student-destination-image-1.png" alt="学员去向"
        style="width: 60%; margin: 0 auto;">
    </div>
    <FooterView></FooterView>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from '@/components/HeaderView.vue'
import FooterView from '@/components/FooterView.vue'

export default {
  name: 'StudentView',
  components: {
    HeaderView,
    FooterView
  },
  data() {
    return {
    }
  },
  methods: {

  },
  mounted() {
  },
  computed: {

  }
};
</script>

<style scoped>
.main {
  padding-top: 55px;
  /* 与导航栏高度一致的内边距 */
}

.section-title {
  font-size: 1.8em;
  /* 字体大2号 */
  font-weight: bold;
  /* 加粗 */
  text-align: center;
  /* 文本居中 */
  margin-bottom: 40px;
  /* 与内容保持一定距离 */
  letter-spacing: 2px;
}

.student-container {
  background-image: url('https://kodo.kepinfm.com/website/path-to-student-back-image.jpg');
  /* 替换为你的背景图片路径 */
  background-size: cover;
  /* 使背景图片覆盖整个容器 */
  background-position: center;
  height: 400px;
  display: flex; /* 使用Flexbox */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative; /* 为阴影设置相对定位 */
}

.brand-introduction {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex; /* 使用Flexbox */
  flex-direction: column; /* 设置为列布局 */
  justify-content: center; /* 垂直居中文本 */
  align-items: center; /* 水平居中文本 */
}


/* 学员去向 */
.student-destination {
  padding-bottom: 64px;
  padding-top: 60px;
  /* 添加一些间隔 */
  text-align: center;
  /* 使图片居中显示 */
  background-color: #f6f9fb;
}

.student-destination img {
  max-width: 100%;
  /* 使图片最大宽度为容器宽度 */
  height: auto;
  /* 保持图片的宽高比 */
  border-radius: 5px;
  /* 圆角 */
}

/* 学员好评板块 */
.student-testimonials {
  padding-bottom: 64px;
  /* 添加一些间隔 */
  text-align: center;
  /* 使图片居中显示 */
  padding-top: 60px;
}

.testimonials-row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  /* 行之间的间距 */
}


.testimonials-row img {
  width: calc(50% - 10px);
  /* 两个图片平分宽度，减去间隔 */
  margin-right: 20px;
  /* 图片之间的间隔 */
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* 添加灰色阴影 */
}

.testimonials-row img:last-child {
  margin-right: 0;
  /* 去除最后一个图片的右边距 */
}

/* 学员就职企业 */
.cooperative-enterprises {
  padding-bottom: 64px;
  /* 添加一些间隔 */
  text-align: center;
  /* 使图片居中显示 */
  padding-top: 60px;
  background-color: #f6f9fb;
}

.cooperative-enterprises img {
  max-width: 100%;
  /* 使图片最大宽度为容器宽度 */
  height: auto;
  /* 保持图片的宽高比 */
  border-radius: 5px;
  /* 圆角 */
}

/* 学员分布 */
.student-distribution {
  padding-bottom: 60px;
  /* 添加一些间隔 */
  text-align: center;
  /* 使图片居中显示 */
  padding-top: 64px;
}

.student-distribution img {
  max-width: 100%;
  /* 使图片最大宽度为容器宽度 */
  height: auto;
  /* 保持图片的宽高比 */
  border-radius: 5px;
  /* 圆角 */
}
</style>