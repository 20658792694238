<template>
  <div class="main">
    <HeaderView></HeaderView>
    <div class="teacher-container">
      <div class="brand-introduction">
        <p style="font-size: 48px; line-height: 62px; font-weight: normal; letter-spacing: 4px;">
          名师把关  助力求职
        </p>
        <p style="font-size: 24px; line-height: 36.2px; margin-top: 10px; letter-spacing: 4px;">
          各行业精英导师陪伴式指导，求职能力高阶跨越
        </p>
      </div>
    </div>
    <!-- 师资团队板块 -->
    <div class="faculty-container">
      <h2 class="section-title" style="margin-bottom: 60px;">部分顾问及导师</h2>
      <div class="faculty-members">
        <div v-for="(coach, index) in coaches" :key="index" class="coach">
          <img :src="coach.image" :alt="coach.name" class="coach-avatar">
          <div class="coach-info">
            <h3 class="coach-name">{{ coach.name }}</h3>
            <p class="coach-position">{{ coach.position }}</p>
          </div>
        </div>
      </div>
    </div>
    <FooterView></FooterView>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from '@/components/HeaderView.vue'
import FooterView from '@/components/FooterView.vue'

export default {
  name: 'FacultyView',
  components: {
    HeaderView,
    FooterView
  },
  data() {
    return {
      activeMilestone: null, // 当前激活的里程碑
      coaches: [
        { name: '万校长', position: '211高校毕业，原北汽集团任职，多年央国企指导经验。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-10.png' },
        { name: '欧阳老师', position: '湖南工商大学教授，复旦大学博士，曾担任新华联集团、三一重工、远大高管，湖南省民营经济研究会常务理事。', image: 'https://kodo.kepinfm.com/website/teacher/wu-img.png' },
        { name: '罗老师', position: '湖南大学工商管理学院副教授，国家二级心理咨询师，央国企资深人力资源和管理顾问。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-2.png' },
        { name: '张老师', position: '原比亚迪人工智能研究院院长，某国企车企高管。资深求职规划导师。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-8.png' },
        { name: '黎老师', position: '本硕中山大学、博士北京大学，现药明生物助理主任，医药行业资深求职顾问。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-14.png' },
        { name: '张老师', position: '本硕四川大学，博士北京大学，博士后芝加哥大学、加州大学圣塔芭芭拉分校。现博士生导师，央国企资深人力资源和管理顾问。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-4.png' },
        { name: '张老师', position: '本硕毕业于985高校、拥有海外留学经验，对金融、工程、机械、建筑、医疗等央国企的基本情况与招聘流程有深入了解。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-15.png' },
        { name: '肖老师', position: '硕士，曾任职百强央企中国中车、龙头国企中联重科、湖南省人才集团人才培训事业部部长，中国500强大汉控股集团集团教培中心总经理。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-6.png' },
        { name: '颜老师', position: '原央企华润集团10多年人力资源部门工作，服务过郑州移动、无锡新区政府、明源软件、融侨地产、中化石油等政府企业，资深央国企人力资源指导老师。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-3.png' },
        { name: '吴老师', position: '8年人力资源管理经验，熟悉外贸/金融/运营/设计/人事等职位的招聘要求，1V1职业规划和就业辅导超过500人次。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-16.png' },
        { name: '唐老师', position: '湖南高校就业指导老师，原粮食局工作人员，多次参与组织省人事考试院、省财政厅、卫计委、检察院书记员、烟草系统的各类考试、医院岗位招聘的面试，有丰富的行业从业经验。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-5.png' },
        { name: '邓老师', position: '硕士，国内大型央国企金控集团管理人员，了解央国企工作流程，熟悉央国企招聘方式、资深央国企求职规划老师。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-7.png' },
        { name: '徐老师', position: '211本科，巴斯大学硕士，科睿唯安战略客户经理，资深职业规划导师。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-13.png' },
        { name: '杨老师', position: '985本硕，中国农业大学学院分团委书记、益阳水文水资源勘测局公务员、湖南工艺美术职业学院人事处副科级干部。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-11.png' },
        { name: '胡老师', position: '在校期间曾于芒果TV、百度、美团民宿实习，校招拿到4家央企宣传类岗位offer，从业以来累计辅导学生500+，并指导学生获得华润、格力、华侨城、广电、百度等多家实习/校招Offer。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-25.png' },
        { name: '高老师', position: '曾履职500强央国企，拥有丰富的管理经验，熟知央国企招聘市场需求、招聘流程和人才评价标准，资深央国企实习/就业指导老师。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-30.jpg' },
        { name: '卢老师', position: '高级工程师，国家一级建造师，曾就职于中建集团、中冶集团，11年央企工作经验，对央国企行业分类、职业发展、所需人才特质有深刻了解。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-30.png' },
        { name: '岳老师', position: '曾就职于500强央国企，拥有多年人力资源与职业发展背景，擅长根据每位学员的专业背景、兴趣特长及职业目标，量身定制个性化的求职策略与职业规划方案。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-29-1.png' },
        { name: '粟老师', position: '曾在某国企和国内top3互联网企业工作，了解央国企和大企业用人要求。对岗位申请、自我定位、挖掘优势有深入把握，可从实习选择、简历修改、面试准备、offer选择等求职全流程提供咨询。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-18.png' },
        { name: '何老师', position: '校招期间获得多家科技企业offer，多年金融科技国企技术岗工作经验。对专业内技术掌握扎实，具有丰富的简历修改及面试辅导经验。主攻简历修改、技术岗实习、校招面试辅导。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-19.png' },
        { name: '李老师', position: '善于讲解国企群面、结构化面试技巧及自我认知提升。对于面试表达和职场沟通技巧研究深入，能够引导学生在提升面试技能的同时向学生人到职场人角色转变。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-17.png' },
        { name: '韩老师', position: '山东大学硕士，大疆创新智能制造策略高级研究员。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-12.png' },
        { name: '马老师', position: '中国航空工业集团研发设计高级工程师。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-20.png' },
        { name: '姜老师', position: '11年财会工作经验，5A级税务师事务所合伙人及央企旗下公司合伙人。对财会方向求职有深刻的个人见解，擅于帮助他人分析自身优劣势、针对性的设计自我介绍和修改简历。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-21.png' },
        { name: '谭老师', position: '毕业于国内985院校-机械工程专业，曾在济南二机床集团担任研发设计高级工程师、某汽车国企担任研发工程师一职，深谙国企对求职者的专业要求、技能偏好及发展前景。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-32.png' },
        { name: '欧阳老师', position: '2段大型央国企工作经验，曾在珠江啤酒集团、中国航天科工担任核心岗位，擅长结合学生个人优势与国企用人需求，制定个性化求职策略，助力精准匹配目标岗位。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-31.png' },
        { name: '杨老师', position: '毕业于国内211院校-车辆工程专业，金龙汽车、中国中车2段大型国有企业工作经历，三一集团知名企业工作经历，注重培养学生的综合素质，包括行业知识、专业技能、沟通能力等等，确保学生在激烈的国企竞争中脱颖而出。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-33.png' },
        { name: '郭老师', position: '985硕士，10年+大型企业工作经历，5年+职业生涯咨询经验。各项指导上千人，筛选过的简历上万份，在职业生涯规划、简历优化、面试技巧、人才测评等方面具有独特的认识。', image: 'https://kodo.kepinfm.com/website/teacher/wu-img.png' },
        { name: '刘老师', position: '毕业于西安交大，现就职于某TOP电力集团。曾于校招中斩获四家央企offer，善于分析学员实际情况，站在学生角度，开展求职咨询、指导与服务，提供针对性解决办法。', image: 'https://kodo.kepinfm.com/website/teacher/to-path-teacher-img-24.png' },
      ],
    }
  },
  methods: {
    setActiveMilestone(milestone) {
      this.activeMilestone = milestone;
    },
    handleSubmit() {
      if (this.isFormValid) {
        // 表单提交逻辑
        console.log('Form submitted:', this.form);
        alert('表单提交成功！');
      } else {
        // 表单校验失败逻辑
        alert('请填写所有必填项！');
      }
    }
  },
  mounted() {
    this.activeMilestone = this.timeline[0]; // 初始化时设置2017年的里程碑为激活状态
  },
  computed: {
    isFormValid() {
      // 简单的字段校验逻辑
      return (
        this.form.name.trim() !== '' &&
        this.form.mobile.trim() !== '' &&
        this.form.university.trim() !== '' &&
        this.form.graduation !== '' &&
        this.form.industry.trim() !== ''
      );
    }
  }
};
</script>

<style scoped>
.main {
  padding-top: 55px;
  /* 与导航栏高度一致的内边距 */
}
.section-title {
  font-size: 1.8em;
  /* 字体大2号 */
  font-weight: bold;
  /* 加粗 */
  text-align: center;
  /* 文本居中 */
  margin-bottom: 40px;
  /* 与内容保持一定距离 */
  letter-spacing: 2px;
}
/* 通用样式 */
h2 .section-title {
  font-size: 1.2em;
  /* 默认字体大小 */
  font-weight: bold;
}

/* 导师团队 */
.faculty-container {
  padding-top: 64px;
  padding-bottom: 90px;
  background-color: #f6f9fb;
}

.faculty-members {
  display: flex;
  flex-wrap: wrap;
  width:70%;
  margin: 0 auto;
}

.coach {
  width: calc(32% - 0px);
  /* 假设间隔为20px，这里减去10px */
  margin-bottom: 20px;
  /* 导师之间垂直间隔 */
  background-size: cover;
  background-position: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* 设置为垂直方向 */
  align-items: center;
  /* 垂直居中 */
  ;
  background-color: #fff;
  /* 背景颜色 */
  /* 边框颜色，浅酒红色 */
  border-radius: 5px;
  /* 边框圆角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* 轻微的阴影效果 */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-left: 12px;
}

.coach:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* 鼠标悬停时阴影更加明显 */
}

.coach-info {
  display: flex;
  flex-direction: column;
  /* 垂直排列文本 */
  justify-content: center;
  /* 文本在交叉轴上居中 */
  /* 其他样式，如字体样式等 */
  width: 100%;
}

.coach-avatar {
  width: 100%;
  height: 350px;
  margin: 0 auto;
  /* 图片水平居中 */
}

.coach-name {
  color: #8b0000;
  /* 黑红色 */
  font-size: 1em;
  /* 根据需要调整 */
  margin-bottom: 6px;
  text-align: center;
  margin-top: 6px;
  letter-spacing: 2px;
}

.coach-position {
  color: #333;
  /* 普通字体颜色 */
  font-size: 0.8em;
  /* 小一号的字体 */
  margin: 0;
}

/* 鼠标悬停效果 */
.coach:hover {
  transform: scale(1.05);
}

.coach:hover {
  transform: scale(1.05);
  /* 鼠标悬停时放大效果 */
}

.coach p {
  margin: 0;
  padding: 14px;
  background-color: #f9f9f9;
  height: 150px;
  letter-spacing: 1px;
  font-size: 14px;
}

/* 两排导师的样式调整 */
@media (max-width: 768px) {
  .coach {
    width: calc(50% - 10px);
    /* 在小屏幕上展示两列 */
  }
}
.teacher-container {
  background-image: url('https://kodo.kepinfm.com/website/path-to-teacher-back-image.jpg');
  /* 替换为你的背景图片路径 */
  background-size: cover;
  /* 使背景图片覆盖整个容器 */
  background-position: center;
  height: 400px;
  display: flex; /* 使用Flexbox */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  position: relative; /* 为阴影设置相对定位 */
}

.brand-introduction {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex; /* 使用Flexbox */
  flex-direction: column; /* 设置为列布局 */
  justify-content: center; /* 垂直居中文本 */
  align-items: center; /* 水平居中文本 */
}
</style>