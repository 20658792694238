<template>
  <div class="home-container">
    <HeaderView></HeaderView>
    <div class="content-container">
      <div class="boxes-container">
        <p style="font-size: 54px; font-weight: 600; line-height: 65px; color: #fff; margin-bottom: 10px; letter-spacing: 4px;">为普通年轻人创造机会</p>
        <p style="font-size: 24px; font-weight: 500; line-height: 40px; color: #fff; margin-top: 20px; margin-bottom: 10px; letter-spacing: 4px;">专注于大学生求职就业的全方位服务平台</p>
      </div>
      <div class="stats-container">
        <div class="stat-item" v-for="stat in stats" :key="stat.id">
          <span class="number" v-if="stat.sign" :data-sign="stat.sign">{{ stat.number }}</span>
          <span class="number no-sign" v-else>{{ stat.number }}<span class="percent">{{ stat.percent }}</span></span>
          <span class="text">{{ stat.text }}</span>
        </div>
      </div>
      <!-- 新增的跳动箭头和文字 -->
      <!-- <div class="more-info" @click="toAboutPage()">
        <div class="circle">
          <span class="arrow">&#x2193;</span>
        </div>
        <div class="text">了解更多</div>
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from '@/components/HeaderView.vue'

export default {
  name: 'HomeView',
  components: {
    HeaderView
  },
  data() {
    return {
      stats: [
        { id: 1, number: '1000', text: '求职活跃社群', sign: '+' },
        { id: 2, number: '50W', text: '全媒体粉丝数', sign: '+' },
        { id: 3, number: '100', text: '知名企业导师', sign: '+' },
        { id: 4, number: '200', text: '学员覆盖城市', sign: '+' },
        { id: 5, number: '8000', text: '学员Offer数量', sign: '+' },
        { id: 6, number: '98', percent: '%', text: '学员好评率' }
      ]
    };
  },
  methods: {
    toAboutPage () {
      this.$router.push({ name: 'about' });
    }
  }
}
</script>
<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 水平居中 */
  justify-content: flex-start;
  /* 默认是垂直顶部对齐 */
  min-height: 100vh;
  background-image: url('https://cdn.kepinfm.cn/exp/admin/media/1642409966.png');
  background-size: cover, auto; /* 渐变层不需要覆盖，自动高度即可 */
  background-position: center;
  background-repeat: no-repeat; /* 防止背景图重复 */
}

.content-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: center;
  /* 确保内部文本水平居中 */
  margin: auto;
  /* 垂直居中 */
}


.boxes-container {
  width: 100%;
  margin-bottom: 40px;
  margin-top: 60px;
  /* 根据需要调整 */
}


.box {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 20px;
  width: calc(33% - 20px);
  height: 200px;
  /* 设置固定高度 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* 添加阴影以产生立体感 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* 垂直居中盒子内的文本 */
  align-items: center;
  /* 水平居中盒子内的文本 */
}

.mission {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.vision {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.values {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 4px;
  opacity: 0.9;
}

.stat-item {
  flex-basis: calc(33.333% - 10px);
  /* 减去间隔 */
  margin: 5px;
  color: white;
}

.number {
  position: relative;
  /* 为伪元素定位提供参考 */
  font-size: 2em;
  font-weight: bold;
  color: #fdcd00;
  display: inline-block;
  /* 或者使用 block，根据布局需要 */
  margin-bottom: 5px;
  /* 根据需要调整 */
}

.number:not(.no-sign):after {
  content: '+';
  left: 100%;
  /* 使伪元素位于数字的右侧 */
  bottom: 4px;
  /* 保持底部距离为 4px */
  font-size: 0.5em;
  color: #fdcd00;
  margin-left: 2px;
  /* 添加 10px 的左间距 */
}

.number.no-sign {
  padding-right: 0;
  /* 移除不需要 '+' 符号的元素的右内边距 */
}

.number .percent {
  display: inline;
  font-size: 0.5em;
  color: #fdcd00;
  margin-left: 2px;
  /* Adjust spacing between number and percent sign */
}


.text {
  display: block;
  color: #000;
}

.number:after {
  content: "+";
  position: absolute;
  /* 使用绝对定位 */
  right: 5px;
  /* 根据需要调整 */
  bottom: 5px;
  /* 根据需要调整 */
  font-size: 0.5em;
  /* 根据数字大小调整 */
  color: #fdcd00;
  /* 保持与数字相同的颜色 */
}

.number+span {
  font-size: 1em;
  vertical-align: super;
}

.number:not(:empty):after {
  content: attr(data-sign);
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.5em;
  color: #fdcd00;
}

/* 调整愿景、使命、价值观的样式 */
.mission .title,
.vision .title,
.values .title {
  font-size: 3em;
  /* 字号放大3倍 */
  font-weight: bold;
  /* 加粗 */
  color: #fdcd00;
  /* 设置颜色 */
}

/* 调整描述文本的样式 */
.mission .description,
.vision .description,
.values .description {
  font-size: 1.5em;
  /* 字体放大1倍 */
  color: white;
  /* 设置颜色为白色 */
}

.more-info {
  display: inline-block;
  /* 或者使用 flex 布局，根据需求 */
  text-align: center;
  /* 确保内部文本水平居中 */
  margin-top: 20px;
  /* 添加一些间隔 */
  cursor: pointer;
}

.circle {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  /* 设置背景为透明 */
  border: 1px solid #ccc;
  /* 为了可视化圆，添加一个边框 */
  position: relative;
  margin-bottom: 10px;
  /* 添加一些间隔 */
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em; /* 根据圆的大小调整箭头的大小 */
  color: #ffffff; /* 箭头颜色设置为白色 */
  animation: bounce-arrow 0.5s ease-in-out infinite; /* 应用跳动动画 */
  line-height: 1; /* 确保箭头垂直居中 */
}

.more-info .text {
  color: #ffffff;
  font-size: 1em;
  /* 字体大小可以根据需要调整 */
}

@keyframes bounce-arrow {
  0%, 100% {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  50% {
    top: 40%; /* 跳动的高度，根据圆的大小和期望的跳动距离调整 */
  }
}
</style>