<template>
  <div class="main">
    <HeaderView></HeaderView>
    <!-- 品牌介绍板块 -->
    <div class="about-container">
      <div class="content-cover">
        <p
          style="color: #fff; font-size: 48px; line-height: 62px; font-weight: normal; text-align: center; margin-bottom: 20px; margin-top: 420px; letter-spacing: 4px;">
          深耕大学生求职就业领域七年</p>
        <p
          style="color: #fff; font-size: 24px; text-align: center; margin-bottom: 120px; font-weight: normal; letter-spacing: 4px;">
          助力圆梦名企，实现高质量就业</p>
        <div class="brand-introduction">
          <p style="font-size: 24px; font-weight: 600;">
            我们是课聘
          </p>
          <p>
            课聘是国内首批专注于大学生求职就业的全方位服务机构，秉持着“为普通年轻人创造机会”的使命，团队深耕大学生就业领域7年，具有丰富的行业从业经验。
          </p>
          <p>
            顶尖教学团队由央国企、金融、互联网、快消、化工、能源、财会、机械等各个领域的企业工作人员、资深HR和拥有多年求职经验的导师组成。
          </p>
          <p>
            课聘致力于为大学生解决各类求职问题，让校招更加公平、高效、简单。
          </p>
        </div>
      </div>
    </div>
    <!-- 发展历程时间轴 -->
    <div class="timeline-container">
      <h2 class="section-title" style="padding-top: 50px;">发展历程</h2>
      <img src="https://kodo.kepinfm.com/website/path-to-process-image.png" alt="发展历程"
        style="width: 60%; margin: 0 auto;">
      <!-- <div class="timeline-item-wrapper">
        <div v-for="(milestone, index) in timeline" :key="index" class="timeline-item">
          <div class="year-marker" :class="{ 'active': milestone === activeMilestone }"
            @mouseover="setActiveMilestone(milestone)">
            {{ milestone.year }}
          </div>
          <div class="milestone-description" v-if="milestone === activeMilestone">
            <h3>{{ milestone.title }}</h3>
            <p>{{ milestone.description }}</p>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 企业文化 -->
    <div class="corporate-container">
      <h2 class="section-title" style="margin-bottom: 60px;">企业文化</h2>
      <div class="culture-content">
        <div class="culture-text">
          <h3>愿景</h3>
          <p>秒懂年轻人的科技型平台</p>
          <h3>使命</h3>
          <p>为普通年轻人创造机会</p>
          <h3>价值观</h3>
          <p>简单、快乐、专业、有爱</p>
        </div>
        <div class="culture-image">
          <img src="https://kodo.kepinfm.com/website/path-to-vision-image-1.jpg" alt="企业文化">
        </div>
      </div>
    </div>
    <!-- 合作企业 -->
    <div class="company-base">
      <h2 class="section-title">合作企业</h2>
      <img src="https://kodo.kepinfm.com/website/path-to-cooperative-enterprises-image.png" alt="合作企业"
        style="width: 60%; margin: 0 auto;">
    </div>
    <!-- 部分校企基地 -->
    <div class="school-base">
      <h2 class="section-title">部分校企基地</h2>
      <img src="https://kodo.kepinfm.com/website/path-to-school-base-img.png" alt="校企基地"
        style="width: 60%; margin: 0 auto;">
    </div>
    <FooterView></FooterView>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderView from '@/components/HeaderView.vue'
import FooterView from '@/components/FooterView.vue'


export default {
  name: 'AboutView',
  components: {
    HeaderView,
    FooterView
  },
  data() {
    return {
      activeMilestone: null, // 当前激活的里程碑
      timeline: [
        {
          year: '2017年',
          title: '企业成立',
          description: '获得天使投资人种子轮投资',
          image: 'https://img01.51jobcdn.com/fansImg/CompLogo/9/8646/8645975/296685643807491862992_300.png?1714995685620'
        },
        {
          year: '2018年',
          title: '研发课聘线上平台',
          description: '成立首个高校产教融合基地',
          image: 'https://img01.51jobcdn.com/fansImg/CompLogo/9/8646/8645975/296685643807491862992_300.png?1714995685620'
        },
        {
          year: '2019年',
          title: '与数百家企业合作',
          description: '进入50多个高校开展校招，帮助学生就业和企业人力资源发展',
          image: 'https://img01.51jobcdn.com/fansImg/CompLogo/9/8646/8645975/296685643807491862992_300.png?1714995685620'
        },
        {
          year: '2020年',
          title: '成立课聘研习社、财会精英圈等学生社群，覆盖线上线下',
          description: '开展学生职场交流，走进企业等数百场活动',
          image: 'https://img01.51jobcdn.com/fansImg/CompLogo/9/8646/8645975/296685643807491862992_300.png?1714995685620'
        },
        {
          year: '2021年',
          title: '获得深圳、上海投资机构天使轮投资',
          description: '荣获高校最佳培训榜样品牌',
          image: 'https://img01.51jobcdn.com/fansImg/CompLogo/9/8646/8645975/296685643807491862992_300.png?1714995685620'
        },
        {
          year: '2022年',
          title: '入驻视频号、抖音、快手、小红书、B站',
          description: '获得数十万家长和学生的关注和喜爱',
          image: 'https://img01.51jobcdn.com/fansImg/CompLogo/9/8646/8645975/296685643807491862992_300.png?1714995685620'
        },
      ],
      coaches: [
        { name: '欧阳老师', position: '湖南工商大学教授，复旦大学博士，曾担任新华联集团、三一重工、远大高管，湖南省民营经济研究会常务理事。', image: 'https://kodo.kepinfm.com/website/path-to-image-1.png' },
        { name: '罗老师', position: '湖南大学工商管理学院副教授，国家二级心理咨询师，央国企资深人力资源和管理顾问。', image: 'https://kodo.kepinfm.com/website/path-to-image-2.png' },
        { name: '颜老师', position: '原央企华润集团10多年人力资源部门工作，服务过郑州移动、无锡新区政府、明源软件，融侨地产，中化石油等政府企业，资深央国企人力资源指导老师。', image: 'https://kodo.kepinfm.com/website/path-to-image-3.png' },
        { name: '张老师', position: '本硕四川大学，博士北京大学。博士后芝加哥大学，加州大学圣塔芭芭拉分校。现博士生导师，央国企资深人力资源和管理顾问。', image: 'https://kodo.kepinfm.com/website/path-to-image-4.png' },
        { name: '唐老师', position: '湖南高校就业指导老师，原粮食局工作人员，多次参与组织省人事考试院、省财政厅、卫计委、检察院书记员、烟草系统的各类考试、医院岗位招聘的面试，有丰富的行业从业经验。', image: 'https://kodo.kepinfm.com/website/path-to-image-5.png' },
        { name: '肖老师', position: '硕士，曾任职百强央企中国中车、龙头国企中联重科、湖南省人才集团人才培训事业部部长，中国500强大汉控股集团集团教培中心总经理。', image: 'https://kodo.kepinfm.com/website/path-to-image-6.png' },
        { name: '邓老师', position: '硕士，国内大型央国企金控集团管理人员，了解央国企工作流程，熟悉央国企招聘方式、资深央国企求职规划老师。', image: 'https://kodo.kepinfm.com/website/path-to-image-7.png' },
        { name: '张老师', position: '原比亚迪人工智能研究院院长，某国企车企高管。资深求职规划导师。', image: 'https://kodo.kepinfm.com/website/path-to-image-8.png' },
        { name: '向老师', position: '武汉大学硕士，央企中国光大环境(集团)有限公司管理层。', image: 'https://kodo.kepinfm.com/website/path-to-image-9.png' },
        { name: '万校长', position: '211高校毕业，原北汽集团任职，多年央国企指导经验。', image: 'https://kodo.kepinfm.com/website/path-to-image-10.png' },
        { name: '杨梦云', position: '985本硕，中国农业大学学院分团委书记、益阳水文水资源勘测局公务员、湖南工艺美术职业学院人事处副科级干部。', image: 'https://kodo.kepinfm.com/website/path-to-image-11.png' },
        { name: '韩祥程', position: '山东大学硕士，大疆创新智能制造策略高级研究员。', image: 'https://kodo.kepinfm.com/website/path-to-image-12.png' },
        { name: '徐一然', position: '211本科，巴斯大学硕士，科睿唯安战略客户经理，资深职业规划导师。', image: 'https://kodo.kepinfm.com/website/path-to-image-13.png' },
        { name: '黎波', position: '本硕中山大学、博士北京大学,现药明生物助理主任，医药行业资深求职顾问。', image: 'https://kodo.kepinfm.com/website/path-to-image-14.png' },
        { name: '张组长', position: '本硕毕业于985高校、拥有海外留学经验，对金融、工程、机械、建筑、医疗等央国企的基本情况与招聘流程有深入了解。', image: 'https://kodo.kepinfm.com/website/path-to-image-15.png' },
        { name: '吴老师', position: '8年人力资源管理经验，熟悉外贸/金融/运营/设计/人事等职位的招聘要求，1V1职业规划和就业辅导超过500人次。', image: 'https://kodo.kepinfm.com/website/path-to-image-16.png' },
        { name: '李老师', position: '善于讲解国企群面、结构化面试技巧及自我认知提升。对于面试表达和职场沟通技巧研究深入，能够引导学生在提升面试技能的同时向学生人到职场人角色转变。', image: 'https://kodo.kepinfm.com/website/path-to-image-17.png' },
        { name: '粟老师', position: '曾在某国企和国内top3互联网企业工作，了解央国企和大企业用人要求。对岗位申请、自我定位、挖掘优势有深入把握，可从实习选择、简历修改、面试准备、offer选择等求职全流程提供咨询。', image: 'https://kodo.kepinfm.com/website/path-to-image-18.png' },
        { name: '王老师', position: '校招期间获得多家科技企业offer，多年金融科技国企技术岗工作经验。对专业内技术掌握扎实，具有丰富的简历修改及面试辅导经验。主攻简历修改、技术岗实习、校招面试辅导。', image: 'https://kodo.kepinfm.com/website/path-to-image-19.png' },
        { name: '马敬鑫', position: '中国航空工业集团研发设计高级工程师。', image: 'https://kodo.kepinfm.com/website/path-to-image-20.png' },
        { name: '姜老师', position: '11年财会工作经验，5A级税务师事务所合伙人及央企旗下公司合伙人。对财会方向求职有深刻的个人见解，擅于帮助他人分析自身优劣势、针对性的设计自我介绍和修改简历。', image: 'https://kodo.kepinfm.com/website/path-to-image-21.png' },
        { name: '谭老师', position: '211高校硕士，10多年大型央国企金控集团工作经验，了解央国企工作流程，熟悉央国企招聘方式。', image: 'https://kodo.kepinfm.com/website/path-to-image-22.png' },
        { name: '郭老师', position: '10年+人力资源管理经验，5年+职业生涯咨询经验。面试超上万人，筛选过的简历超过10万份，职业成熟度高，在简历优化、面试技巧、职业生涯规划、人才测评等方面具有独特的认识。', image: 'https://kodo.kepinfm.com/website/path-to-image-23.png' },
        { name: 'Yang Liu', position: '毕业于西安交大，现就职于某TOP电力集团。曾于校招中斩获四家央企offer，善于分析学员实际情况，站在学生角度，开展求职咨询、指导与服务，提供针对性解决办法。', image: 'https://kodo.kepinfm.com/website/path-to-image-24.png' },
        { name: '胡老师', position: '在校期间曾于芒果TV、百度、美团民宿实习，校招拿到4家央企宣传类岗位offer，从业以来累计辅导学生500+，并指导学生获得华润、格力、华侨城、广电、百度等多家实习/校招Offer。', image: 'https://kodo.kepinfm.com/website/path-to-image-25.png' },
        { name: '曾老师', position: '曾就职于某大型央企，资深面试指导老师，求职方面拥有多次成功经验，具有丰富的面试经验，辅导学员2000+，擅长面试问题诊断与针对性面试提升。', image: 'https://kodo.kepinfm.com/website/path-to-image-26.png' },
        { name: '王老师', position: '高级人力资源师，7年央国企人力资源管理经验，多次负责湖南地区校招，专业简历修改指导老师，擅长经历挖掘与简历精修。', image: 'https://kodo.kepinfm.com/website/path-to-image-27.png' },
        { name: '郑老师', position: '资深求职方向咨询师，曾任职与某咨询外企，擅长行业分析与求职路径规划，把控求职方向，所带学员遍布互联网、金融、建筑、快消、机械等多个领域。', image: 'https://kodo.kepinfm.com/website/path-to-image-28.png' },
      ]
    }
  },
  methods: {
    setActiveMilestone(milestone) {
      this.activeMilestone = milestone;
    },
    handleSubmit() {
      if (this.isFormValid) {
        // 表单提交逻辑
        console.log('Form submitted:', this.form);
        alert('表单提交成功！');
      } else {
        // 表单校验失败逻辑
        alert('请填写所有必填项！');
      }
    }
  },
  mounted() {
    this.activeMilestone = this.timeline[0]; // 初始化时设置2017年的里程碑为激活状态
  },
  computed: {
    
  }
};
</script>

<style scoped>
.main {
  padding-top: 55px;
  /* 与导航栏高度一致的内边距 */
}

/* 通用样式 */
h2 .section-title {
  font-size: 1.2em;
  /* 默认字体大小 */
  font-weight: bold;
}

/* 品牌介绍板块样式 */
.about-container {
  background-image: url('https://kodo.kepinfm.com/website/path-to-your-background-image-1.jpg');
  /* 替换为你的背景图片路径 */
  background-size: cover;
  /* 使背景图片覆盖整个容器 */
  background-position: center;
  /* 根据需要添加内边距 */
  height: 400px;
}

.brand-introduction {
  background-color: #fff;
  /* 透明的黑色背景 */
  /* 字体颜色为白色 */
  padding: 20px;
  /* 内边距 */
  border-radius: 20px;
  text-align: center;
  /* 圆角 */
  width: 80%;
  margin: 0 auto;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.brand-introduction p {
  font-size: 0.9em;
  /* 增加字体大小 */
  line-height: 2.6;
  /* 调整行高以优化阅读体验 */
  margin-top: 20px;
  /* 上边距 */
  margin-bottom: 20px;
  /* 下边距 */
  color: #000;
  /* 文本颜色，根据背景调整 */
}

.about-container .content-cover {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* 发展历程时间轴样式 */
.timeline-container {
  text-align: center;
  background-color: #f6f9fb;
  margin-top: 350px;
}

.section-title {
  font-size: 1.8em;
  /* 字体大2号 */
  font-weight: bold;
  /* 加粗 */
  text-align: center;
  /* 文本居中 */
  margin-bottom: 40px;
  /* 与内容保持一定距离 */
  letter-spacing: 2px;
}

.year-marker {
  background-color: #fff;
  /* 时间背景为 #fdcd00 */
  color: black;
  /* 字体颜色为白色 */
  padding: 5px 10px;
  /* 内边距 */
  border-radius: 5px;
  /* 圆角 */
  margin-right: 10px;
  /* 与描述内容保持一定距离 */
  display: inline-block;
  /* 使元素能够与其他内容并排显示 */
  border: solid 1px #fdcd00;
  cursor: pointer;
}

.year-marker.active {
  background-color: #fdcd00;
  /* 激活状态的年份标记背景色 */
  color: #fff;
}

.milestone-description {
  border: 1px solid #ccc;
  /* 线框 */
  padding: 15px;
  /* 内边距 */
  margin-left: 10px;
  /* 与时间标记保持一定距离 */
  background-color: white;
  /* 背景颜色 */
  color: black;
  /* 字体颜色为黑色 */
  border-radius: 5px;
  /* 圆角 */
  transition: all 0.3s ease;
  /* 平滑过渡效果 */
  left: calc(100% + 20px);
  /* 定位在年份标记的右侧 */
  white-space: normal;
  /* 正常换行 */
  width: 520px;
  display: inline-grid;
}

.timeline-item {
  margin: 10px;
}

.timeline-item:hover .year-marker,
.timeline-item .year-marker.active {
  background-color: #fdcd00;
  /* 鼠标悬停或激活时的背景色 */
  color: white;
}

.timeline-item:hover .milestone-description,
.timeline-item .milestone-description.active {
  opacity: 1;
  visibility: visible;
}

.timeline-item-wrapper {
  width: 70%;
  margin: 0 auto;
}


/* 校企基地板块 */
.school-base {
  padding-bottom: 64px;
  /* 添加一些间隔 */
  text-align: center;
  /* 使图片居中显示 */
  padding-top: 60px;
}

.school-base img {
  max-width: 100%;
  /* 使图片最大宽度为容器宽度 */
  height: auto;
  /* 保持图片的宽高比 */
  border-radius: 5px;
  /* 圆角 */
}

/* 合作企业 */
.corporate-container {
  padding-bottom: 90px;
  padding-top: 60px;
}

.culture-content {
  width: 100%;
  max-width: 1200px;
  /* 最大宽度 */
  margin: 0 auto;
  /* 水平居中 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.culture-text {
  height: 400px;
  /* 设置高度为400px */
  width: 480px;
  flex: 0 0 auto;
  /* 修正flex属性，保持固定宽度 */
  padding: 22px;
  background-color: #f0f0f0;
  letter-spacing: 2px;
  display: flex;
  /* 使用Flexbox布局 */
  justify-content: center;
  /* 垂直居中文本 */
  flex-direction: column;
  /* 设置为列布局 */
}

.culture-image {
  height: 400px;
  /* 设置高度为400px */
  width: 400px;
  /* 设置宽度为400px */
  display: flex;
  justify-content: center;
  /* 水平居中图片 */
  align-items: center;
  /* 垂直居中图片 */
}

.culture-image img {
  max-width: 100%;
  /* 最大宽度不超过容器宽度 */
  height: 100%;
  /* 高度自适应保持比例 */
  object-fit: cover;
  /* 保持图片比例填充容器 */
  /* 如果需要裁剪图片以适应容器，可以使用object-fit: contain; */
}

.culture-text h3 {
  margin: 10px 0;
  /* 标题间距 */
  font-size: 1.2em;
  /* 字体大一号 */
}

.culture-text p {
  margin: 12px 0;
  /* 段落间距 */
  font-size: 1em;
  /* 正常字体大小 */
}

/* 合作企业 */
.company-base {
  padding-bottom: 64px;
  /* 添加一些间隔 */
  text-align: center;
  /* 使图片居中显示 */
  padding-top: 60px;
  background-color: #f6f9fb;
}

.company-base img {
  max-width: 100%;
  /* 使图片最大宽度为容器宽度 */
  height: auto;
  /* 保持图片的宽高比 */
  border-radius: 5px;
  /* 圆角 */
}
</style>